import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { ProductPage } from '~/components/ProductPage/ProductPage';
import { Any, IProduct, Kit } from '../types/types';

const KitTemplate: React.FC<KitPageProps> = ({ data }) => (
  <Layout>
    <Metadata title={data.kit.name} description={data.kit.shortDescription} />
    <ProductPage product={data.kit} />
  </Layout>
);

export const query = graphql`
  query KitQuery($slug: String, $locale: String) {
    kit: contentfulKit(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      __typename
      ...KitPageFragment
    }
  }
`;

export interface KitPageProps {
  data: {
    kit: Kit;
  };
}

export default KitTemplate;
